<template>
    <span class="text-primary text-sm">{{ error }}</span>
</template>

<script>
export default {
    props: {
        error: {
            type: String,
            default: ''
        }
    }
}
</script>