<template>
    <label v-if="label" :for="id" class="font-bold block">
        {{ label }} <span v-if="required">*</span>
    </label>
    <div class="bg-[#EFEFF0] relative rounded-lg">
        <div v-if="prefix"
            class="absolute inset-y-0 left-0 text-sm md:text-base pl-6 xl:pl-8 pr-3 flex items-center text-brand-gray justify-center pointer-events-none min-w-[3rem] lg:min-w-[3.5rem] after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:h-1/3 after:bg-primary-light after:border-r after:border-gray-400">
            {{ prefix }}
        </div>
        <input :id="id" :value="modelValue" :type="type" :required="required" :placeholder="placeholder"
            :disabled="disabled"
            class="py-4 xl:py-5 placeholder:text-brand-gray w-full text-sm md:text-base bg-[#EFEFF0] disabled:bg-gray-300 rounded-lg w-full"
            :class="{
                'px-6 xl:px-8': !prefix,
                'px-20 xl:px-24': prefix
            }" @input="$emit('update:modelValue', $event.target.value)" />
        <div class="absolute inset-y-0 -right-1 flex items-center" v-if="suffix">
            <div
                class="relative h-full px-2 flex items-center justify-center w-14 before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:h-1/2 before:w-px before:bg-primary-light">
                <component :is="suffix"></component>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Input",
    props: {
        label: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        modelValue: {
            type: [String, Number],
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        prefix: {
            default: undefined,
        },
        suffix: {
            default: undefined,
        }
    },
}
</script>