<template>
    <button class="bg-primary text-white px-10 py-4 rounded-full uppercase text-xs md:text-sm xl:text-lg font-bold font-display disabled:opacity-50" :class="class" :disabled="disabled" :type="type">
        <slot />
    </button>
</template>

<script>
export default {
    name: "Button",
    props: {
        class: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "button",
        }
    }
}
</script>